/*
 *
 * SEBRE
 * Author: Martin Winkler @ PORTA
 * url: http://www.portadesign.cz/
 *
 */

var sliders = [];

/****************************************************************************************************************************************************************************************
DOCUMENT READY
****************************************************************************************************************************************************************************************/

jQuery(document).ready(function($){

/**********************************************
VARIOUS
**********************************************/

  // Show/hide
  $('.sh-trig').toggler();

  // Isotope
  $(window).on('load loadAjaxContent', function() {

    initIsotope();

    // Scrollování ke kotvě
    $('.to-anchor').scrollToAnchor();
  });

  // Pagination
  initPagination();

  // Mobile nav
  $('.mobile-nav .more').on('click', function() {
    var li = $(this).closest('li');

    if (!li.hasClass('active')) {
      li.addClass('active');
    } else {
      li.removeClass('active');
    }

    return false;
  });

  /**********************************************
  FILTER
  **********************************************/

   $('body').on('change', '.form-filter input[type="checkbox"]', function() {
    var checkbox = $(this),
		form = $(this).closest('form'),
		pageInput = form.find('input#page');
    var wrapper = $(this).closest('.toggle');

    if (!checkbox.is(':checked')) {
      wrapper.removeClass('active');
    } else {
      wrapper.addClass('active');
    }

  if (pageInput.length) {
    pageInput.val(1);
  }
  form.submit();
  });

  $('.form-filter .set-projects input[type="checkbox"]').on('change', function() {
    var checkbox = $(this);

    if (!checkbox.is(':checked')) {
      $('.set-cats input').closest('.toggle').removeClass('active');
    }

    $('.set-projects input[type="checkbox"]').filter(':checked').each(function() {
      var checkbox = $(this);
      var theme = $(this).closest('.toggle').attr('data-theme');

      $('.set-cats .'+ theme).find('input').closest('.toggle').addClass('active');      
    });
  });

  $('.form-filter .set-cats input[type="checkbox"]').on('change', function() {
    var checkbox = $(this);
    var id = checkbox.attr('id');

    if (!checkbox.is(':checked')) {
      $('[data-cat="'+ id +'"]').prop('checked',false).closest('.toggle').removeClass('active');
    }
  });

  /**********************************************
  AJAX LOAD ANIMATIONS
  **********************************************/

  $('body').delegate('.teaser-hp .teaser-nav a, .ajax-link', 'click', function(e) {
    var ajaxContent   = $('#ajax-content');
    var url           = $(this).attr('href');
    var id            = $(this).attr('data-id');
    var color         = $(this).attr('data-color');
    var type          = $('#ajax-content > .inner').attr('data-type');


    // Change url
    window.history.replaceState(null, null, url);

    if (!url) {
      return;
    }

    url = url + " #ajax-content > .inner";
    
    // Close navigation if opened
    $(window).trigger('hideNav');

    // Center svg    
    centerSvg(id,false);

    // Prepare teaser for load
    prepareForSwitch(color);

    // Remove title
    removeTitle();

    // Load rest content
    setTimeout(function() {
      ajaxContent.addClass('loading').load(url, function() {
        ajaxContent.removeClass('loading');

        var newBodyClass      = $('#ajax-content > .inner').attr('data-body-class');
        var pageTeaserImage   = $('#ajax-content > .inner').attr('data-teaser-image');
        var pageTitle         = $('#ajax-content > .inner').attr('data-title');
        var lng               = $('#ajax-content > .inner > .nav-hidden > .nav-language-menu');

        console.log(lng);

        // Change title
        document.title = pageTitle;

        // Just in case
        $('html,body').animate({ scrollTop: 0 }, 500 );

        // Change body class (2)
        $('body').removeClass().addClass(newBodyClass);

        // Add teaser image
        $('.teaser').append('<img src="'+ pageTeaserImage +'" alt="" class="teaser-img disabled">');
        setTimeout(function() {
          $('.teaser-img').removeClass('disabled');
        },10);

        //change language menu
        $('#nav-language-wrapper').html(lng);

        // Add scroll down arrow
        if (type == 'hp') {
          var scrollDown = '<a href="#dolu" class="to-anchor scroll-down"><svg class="icon icon-arr-b"><use xlink:href="/static/svg/svg-sprite.svg#icon-arr-b" /></svg><span class="text">vyberte si projekt nebo se posuňte dolů</span></a>';
          setTimeout(function() {
            $('.teaser').append(scrollDown);
          },10);
        }

        // We're done, phew
        setTimeout(function() {
          
          // Trigger that event, baby!
          $(window).trigger('loadAjaxContent');
        },500);
      });
    }, 10);

    return false;
  });

  // Center svg
  function centerSvg(id,centerOnly) {
    var segment     = $('.nav-link[data-id="'+ id +'"]');
    var svg         = segment.closest('svg');
    var teaserNav   = $('.teaser-nav');
    var teaserW     = $('.teaser-content').width();
    var teaserH     = $('.teaser-content').height();
    var primaryNav  = $('.primary-nav');
    var winW        = $(window).width();
    var winH        = $(window).height();
    var svgW        = svg.width();
    var svgH        = svg.height();
    var svgLeft     = svg[0].getBoundingClientRect().left;
    var svgTop      = svg[0].getBoundingClientRect().top;
    var segmentLeft = segment[0].getBoundingClientRect().left;
    var segmentTop  = segment[0].getBoundingClientRect().top;
    var segmentW    = segment[0].getBoundingClientRect().width;
    var segmentH    = segment[0].getBoundingClientRect().height;    
    var newSvgTop   = ((svgH - segmentH) / 2) - segmentTop + svgTop;
    var newSvgLeft  = ((svgW - segmentW) / 2) - segmentLeft + svgLeft;
    var leftOffset  = 150;

    if (winW < 991) {
      leftOffset = 0;
    }

    if (!primaryNav.hasClass('reseted')) {
      // Add class
      teaserNav.addClass('active');

      // Center svg
      teaserNav.css({left: newSvgLeft + leftOffset, top: newSvgTop});
    }

    if (!centerOnly) {
      // Show SMALL links
      showLinksSmall();

      // Show BIG links
      showLinksBig();

      // Color classes
      $('.nav-link').filter('[data-id='+ id +']').addClass('activated');

      // Hide unecessary elements
      $('.teaser-nav .link.big').addClass('disabled');   
      $('.forest').addClass('disabled');   
      $('.teaser .nav-link').addClass('disabled');   
      
      // Show svg/link/line/circle
      $('[data-field='+ id +']').removeClass('disabled');
    }    
  }

  // Reset svg
  function resetSvg() {
    var teaserNav   = $('.teaser-nav');
    var primaryNav  = $('.primary-nav');
    
    // Reset svg position
    teaserNav.css({left: 0, top: 0});

    // Show BIG links
    showLinksBig();

    // Color classes
    $('.nav-link, .forest, .teaser-nav .link.big').removeClass('disabled').removeClass('activated');

    // Hide lines/circle
    $('circle, line, .link.small').removeClass('animated').addClass('disabled');
  }

  // Add H1
  function addTitle() {
    var primaryNav  = $('.primary-nav');
    var title       = $('#ajax-content > .inner').attr('data-h1');

    // Add class titled
    //primaryNav.addClass('titled');
    
    // Show H1
    primaryNav.find('h1').remove();
    primaryNav.prepend('<h1 class="disabled">'+ title +'</h1>');
    
    setTimeout(function() {
      primaryNav.find('h1').removeClass('disabled');
    },100);
  }

  // Remove H1
  function removeTitle() {
    var primaryNav = $('.primary-nav');

    // Remove class titled
    //primaryNav.removeClass('titled');
    
    // Remove h1
    primaryNav.find('h1').remove();
  }

  // Show BIG links (HP)
  function showLinksBig() {
    $('.teaser-nav .link.big').each(function(index, el) {
      var link    = $(this);
      var id      = link.data('id');
      var linkSvg = $('[data-id="' + id + '"]');

      setTimeout(function() {
        linkSvg.addClass('animated');
        link.addClass('animated');

        setTimeout(function(){
          linkSvg.removeClass('active');
        }, 400);
      },100 * index);
    });
  };

  // Show SMALL links (FIELDS)
  function showLinksSmall() {
    var bodyClass = $('#ajax-content > .inner').attr('data-body-class');
    var name      = $('.nav-link.'+ bodyClass).attr('data-id');

    $('.teaser-nav .link.small[data-field="'+ name +'"]').each(function(index, el) {
      var link = $(this);
      var id = link.data('id');
      var linkSvg = $('[data-id="' + id + '"]');

      setTimeout(function() {
        linkSvg.addClass('animated');
        link.addClass('animated');
      },100 * index);
    });
  };

  // Teaser nav svg hover
  function teaserNavSvg() {
    $('.teaser-nav .link, .teaser-nav .link-svg, .teaser-nav circle').hover(function() {
      var id = $(this).attr('data-id');
      $('[data-id="' + id + '"]').addClass('active');
    }, function(){
      var id = $(this).attr('data-id');
      $('[data-id="' + id + '"]').removeClass('active');
    });

    // Enable circle click
    $('.teaser-nav circle').on('click', function() {
      var id = $(this).attr('data-id');
      var href = $('.link[data-id="' + id + '"]').attr('href');
      location.href = href;
    });
  }

  // Disable teaser
  function prepareForSwitch(color) {
    // Hide head
    $('.teaser.teaser-hp .head').addClass('disabled');

    // Change body class (1)        
    $('body').removeClass().addClass(color);

    // Hide small links if visible
    $('.link.small').addClass('disabled');

    // Hide teaser image
    $('.teaser-img').remove();
    
    // Hide scroll down
    //$('.teaser .scroll-down').remove();
  }

  /**********************************************
  ON LOAD ANIMATIONS
  **********************************************/

  var type = $('#ajax-content > .inner').attr('data-type');
  
  // On HP automatically
  if (type == 'hp') {
    // Show BIG links 
    showLinksBig();
  }

  // On FIELD PAGE automatically
  if (type == 'field') {
    
    // Show SMALL links
    showLinksSmall();

    // Add title
    addTitle();
  }

  // Header logo animation
  $('.header').addClass('animated');

  /**********************************************
  MOBILE NAV
  **********************************************/

  var primaryNav          = $('.primary-nav');
  var secondaryNav        = $('.secondary-nav');
  var mobileNav           = $('.mobile-nav');
  var navBtn              = $('.nav-btn');
  var hpNav               = $('.hp-nav');
  var head                = $('.teaser-hp .head');
  var headInner           = $('.teaser-hp .head .inner');
  var subPageNavigation   = $('.subpage-navigation');
  var teaserNav           = $('.teaser-nav');
  var fieldNavMobile      = $('.field-nav-mobile');

  function showNav() {
    secondaryNav.addClass('active');
    navBtn.addClass('active');
    hpNav.addClass('active');
    head.addClass('active');
    mobileNav.addClass('active');
    subPageNavigation.addClass('active');

    var type      = $('#ajax-content > .inner').attr('data-type');
    var teaserImg = $('.teaser-img');

    if (type == 'field') {
      // Show head also
      head.removeClass('disabled');
      
      // Hide title in head
      headInner.addClass('active')

      // Hide teaser image
      teaserImg.addClass('disabled');

      // Hide title
      removeTitle();

      // Prevent from centering when reseted (enable)
      primaryNav.addClass('reseted');

      // Hide field nav
      fieldNavMobile.addClass('disabled');
    }

    resetSvg();
  }

  function hideNav() {
    secondaryNav.removeClass('active');
    navBtn.removeClass('active');
    hpNav.removeClass('active');
    head.removeClass('active');
    mobileNav.removeClass('active');
    subPageNavigation.removeClass('active');

    var type        = $('#ajax-content > .inner').attr('data-type');
    var bodyClass   = $('#ajax-content > .inner').attr('data-body-class');
    var teaserImg   = $('.teaser-img');
    var name        = $('.nav-link.'+ bodyClass).attr('data-id');

    if (type == 'field') {
      // Prevent from centering when reseted (disable)
      primaryNav.removeClass('reseted');

      // Center svg
      centerSvg(name,false);

      // Hide head
      head.addClass('disabled');

      // Show teaser image
      teaserImg.removeClass('disabled');

      // Show title
      addTitle();

      // Center on resize
      $(window).on('resizeEnd', function() {
        centerSvg(name,true);
      });

      // Show field nav
      fieldNavMobile.removeClass('disabled');
    }
  }

  function mobileNavigation() {
    $('.nav-btn').off().on('click',function() {
      if (navBtn.hasClass('active')) {
        hideNav();
      }
      else {
        showNav();
      }
      return false;
    });
  }

  /**********************************************
  TRIGGERS
  **********************************************/

  $(window).on('load loadAjaxContent', function() {

    // Teaser prepare
    $('.teaser').removeClass('disabled');  

    // Init mobile nav
    mobileNavigation();

    // Svg hover
    teaserNavSvg();
  });

  $(window).on('loadAjaxContent', function() {
    
    // Hide navigation
    hideNav();
  }).trigger('load');

  hideNav();

  /**********************************************
  RESIZE END / SCROLL END
  **********************************************/

  var resizeEnd;
  $(window).on('resize', function() {
    clearTimeout(resizeEnd);
    resizeEnd = setTimeout(function() {
      $(window).trigger('resizeEnd');
    }, 100);
  });

  var scrollEnd;
  $(window).on('scroll', function() {
    clearTimeout(scrollEnd);
    scrollEnd = setTimeout(function() {
      $(window).trigger('scrollEnd');
    }, 100);
  });

  /**********************************************
  MODAL BOX
  **********************************************/

  $(function() {
    var trigger = $('.modal-trigger');
    var close = $('.modal-close');

    trigger.each(function(index, element) {
      var id = element.hash;
      var modal = $(id);

      $(element).on('click', function() {
        modal.addClass('active');
        $('body').css({overflow: 'hidden'});
        $('body').addClass('modal-opened');
      });
    });

    // Close link
    close.on('click', function() {
      $('.modal').removeClass('active');
      $('body').css({overflow: 'auto'});
      $('body').removeClass('modal-opened');
    });

    // Overlay click
    $(window).on('click', function(e) {
      $('.modal-box-wrap').each(function(index, element) {
        if (e.target === element) {
          $('.modal').removeClass('active');
          $('body').css({overflow: 'auto'});
          $('body').removeClass('modal-opened');
        }
      });
    });

    // Escape close
    $(window).on('keyup', function(e) {
      if (e.keyCode === 27) {
        $('.modal').removeClass('active');
        $('body').css({overflow: 'auto'});
        $('body').removeClass('modal-opened');
      }
    });
  });

  /**********************************************
  SIMPLE LIGHTBOX
  **********************************************/

  // Simple lightbox
  new SimpleLightbox({
    elements: ".lightbox"
  });

  /**********************************************
  SLIDERS
  **********************************************/

  // Sudo slider
  if ($.fn.sudoSlider) {

    // Item slider
    $('.item-slider').each(function (index) {
      var id = $(this).attr('id');
      sliders[id] = [];
      var el = $(this);

      el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
      el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
      el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
      el.attr('data-slides-phablet') ? sliders[id]['slideNumPhablet'] = el.attr('data-slides-phablet') : sliders[id]['slideNumPhablet'] = sliders[id]['slideNumTablet'];
      el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumPhablet'];
      el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
      el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
      el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
      el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
      el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;
      el.attr('data-pause') ? sliders[id]['slidePause'] = el.data('pause') : sliders[id]['slidePause'] = 5000;
      el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';
      el.attr('data-customlink') ? sliders[id]['slideCustomlink'] = el.data('customlink') : sliders[id]['slideCustomlink'] = 'a.customLink';

      var panelNum = el.find('.panel').length;

      //if (panelNum > sliders[id]['slideNum']) {
        sliders[id]['slider'] = el.find('.slider').sudoSlider({
          effect: sliders[id]['slideEffect'],
          vertical: false,
          auto: sliders[id]['slideAuto'],
          autowidth: false,
          continuous: sliders[id]['slideContinuous'],
          prevNext: sliders[id]['slideNav'],
          numeric: sliders[id]['slideNumeric'],
          pause: sliders[id]['slidePause'],
          slideCount: sliders[id]['slideNum'],
          speed: sliders[id]['slideSpeed'],
          responsive: true,
          customLink: sliders[id]['slideCustomlink'],
          controlsAttr: 'class="slider-nav"',
          prevHtml: '<a href="#" class="prev" aria-label="Předchozí"></a>',
          nextHtml: '<a href="#" class="next" aria-label="Další"></a>',
          initCallback:function () {
            //sliders[id].slider.closest('.item-slider').addClass('active');
          }
        });
      //}
    });

    $(window).on('load resizeEnd', function () {
      for (var id in sliders) {
        if (!sliders.hasOwnProperty(id))
          continue;

        //if ($(sliders[id]['slider']).closest('.item-slider').hasClass('active')) {
          var width = $(window).width();
          var orgSlideCount = sliders[id]['slider'].getOption("slideCount");
          var slideCount;

          if (width >= 1200) {
            slideCount = sliders[id]['slideNum']; // 1200 - 1920
          }
          else if (width >= 992) {
            slideCount = sliders[id]['slideNumDesktop']; // 992 - 1199
          }
          else if (width >= 768) {
            slideCount = sliders[id]['slideNumTablet']; // 768 - 991
          }
          else if (width >= 576) {
            slideCount = sliders[id]['slideNumPhablet']; // 576 - 768
          }
          else {
            slideCount = sliders[id]['slideNumMobile']; // 0 - 575
          }
          if (slideCount != orgSlideCount) {
            sliders[id]['slider'].setOption("slideCount", slideCount);
          }

          // Show/hide nav
          var slider = $(sliders[id]['slider']).closest('.item-slider');
          var slideCount = sliders[id]['slider'].getOption('slideCount');
          var slides = $('.panel',slider).length;

          if (slides <= slideCount) {
            slider.addClass('hide-nav');
          }
          else {
            slider.removeClass('hide-nav');
          }

          sliders[id]['slider'].adjust();
        //}
      };
    }).trigger('resizeEnd');
  }

});

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

//initIsotope();

// Pagination
function initPagination() {
	var loadMoreButton = $('.button-load-more');

	if (loadMoreButton.length) {
		var form = (loadMoreButton.data('form-id')) ? $('#' + loadMoreButton.data('form-id')) : loadMoreButton.closest('.form-load-more');
		if (form.length) {
				var loadMoreInput = form.find('input[name="loadMore"]');
				var loadMore = loadMoreInput.val();
			$(window).on('scroll', function () {
			  loadMore = loadMoreInput.val();

			  if (loadMore == 1) {
				  loadMoreButton = $('.button-load-more');

				  if (loadMoreButton.length && inViewport(loadMoreButton)) {
						loadMoreInput.val(0);
						var page = form.find('input[name="page"]').val();

						if (!page) {
							page = 1;
						}

						page++;

						form.submitForm({"page": page, "loadMore": 0});
					  }
				}

		  });
		}
	}
}

// Isotope
function initIsotope(scrollTop) {
  $('.media .item-list').isotope({
    layoutMode: 'packery',
    packery: {
      columnWidth: '.media .grid-sizer'
    },
    itemSelector: '.media .item-list .item',
    percentPosition: true,
    transformsEnabled: false
  });

  $('.photo-tiles .item-list').isotope({
    layoutMode: 'packery',
    packery: {
      columnWidth: '.photo-tiles .grid-sizer'
    },
    itemSelector: '.photo-tiles .item-list .item',
    percentPosition: true,
  });

}

// Scrollspy
(function($) {
  $.fn.scrollSpy = function (settings) {
    settings = jQuery.extend({
      showSpeed: 100,
      callback: function() {}
    }, settings);

    var topMenu = $(this);

    if (topMenu.length) {
      // Cache selectors
      var lastId,
        topMenuHeight = topMenu.outerHeight(),

        // All list items
        menuItems = topMenu.find("a"),

        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
          var item = $( $(this).attr("href") );
          if (item.length) { return item; }
        });

      // Bind to scroll
      $(window).bind('load scroll', function(){
        // Get container scroll position
        var fromTop = $(this).scrollTop() + topMenuHeight + 50;

        // Get id of current scroll item
        var cur = scrollItems.map(function(){
          if ($(this).offset().top < fromTop)
          return this;
        });
        // Get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id) {
          lastId = id;
          // Set/remove active class
          menuItems
          .parent().removeClass("active")
          .end().filter("[href*=\\#" + id + "]").parent().addClass("active");
        }
      });
    }
  }
})(jQuery);

// Univerzální show/hide
(function($) {
  $.fn.toggler = function (settings) {

    settings = jQuery.extend({
      showSpeed: 100,
      hideSpeed: 100,
      hideTrigger: false,
      slide: false,
      scrollToContent: false,
      offsetScroll: 0,
      hideOnDocumentClick: false,
      documentClickElement: '',
      hideOnEscape: false
    }, settings);

    $(this).each(function(index,value) {

      var trigger = $(this);
      var content = $('#' + trigger.data('content'));

      // Radio nebo checkbox
      if ( trigger.is(':radio') || trigger.is(':checkbox') ) {
        var name = trigger.attr('name');

        $('[name=' + name + ']').on('change', function() {
          content.hide();
        });
        trigger.on('change', function() {
          if ( $(this).is(':checked') ) {
            content.fadeIn(settings.showSpeed);
          }
          else {
            content.hide();
          }
        });
        $(window).on('load',function() {
          if ( trigger.is(':checked') ) {
            content.fadeIn(settings.showSpeed);
          }
        });
      }
      // Standardní element
      else {
        function scrollToContent() {
          var offset = 0;
          if ( settings.offsetScroll ) {
            offset = settings.offsetScroll
          }
          $("html:not(:animated),body:not(:animated)").animate({ scrollTop: $(content).offset().top + offset }, 500);
        }

        function scrollToTrigger() {
          var offset = 0;
          if ( settings.offsetScroll ) {
            offset = settings.offsetScroll + 200
          }
          $("html:not(:animated),body:not(:animated)").animate({ scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset }, 500);
        }

        trigger.on('click',function(event) {
          if ( content.is(':hidden') ) {
            if ( settings.slide ) {
              content.slideDown(settings.showSpeed,function() {
                if ( settings.scrollToContent ) {
                  scrollToContent();
                };
              });
            }
            else {
              content.fadeIn(settings.showSpeed,function() {
                if ( settings.scrollToContent ) {
                  scrollToContent();
                };
              });
            }

            $(this).addClass('active').find('.text').text( $(this).attr('data-less') );

            if ( settings.hideTrigger ) {
              trigger.hide();
            }
          }
          else {
            if ( settings.slide ) {
              content.slideUp(settings.hideSpeed,function() {
                if ( settings.scrollToContent ) {
                  scrollToTrigger();
                };
              });
            }
            else {
              content.fadeOut(settings.hideSpeed,function() {
                if ( settings.scrollToContent ) {
                  scrollToTrigger();
                };
              });
            }
            $(this).removeClass('active').find('.text').text( $(this).attr('data-more') );

            if ( settings.hideTrigger ) {
              $('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
            }
          }
          event.preventDefault();
          //return false;
        });
      }
      // Hide on document click
      if ( settings.hideOnDocumentClick || trigger.attr('data-hideonclick') == 'true' ) {
        $(document).on('click', function(e) {
          if ( (! $(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0) && ( ! trigger.is(e.target) && trigger.has(e.target).length === 0 ) )  {
            content.fadeOut();
            trigger.removeClass('active');
          }
        });
      }
      // Escape button
      if ( settings.hideOnEscape ) {
        $(document).keyup(function(e){
          if (e.keyCode === 27) {
            content.fadeOut();
            trigger.removeClass('active');
          }
        });
      }
    });
  }
})(jQuery);

// Plynulý scroll ke kotvě
(function($) {
  $.fn.scrollToAnchor = function(settings) {

    settings = jQuery.extend({
      speed : 750
    }, settings);

    return this.each(function(){
      var caller = this
      $(caller).click(function (event) {
        event.preventDefault()
        var locationHref = window.location.href
        var elementClick = $(caller).attr("href")

        var destination = $(elementClick).offset().top;

        $("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
          },
          {
            duration: settings.speed,
            //easing: "easeInOutQuint",
            complete: function() {
              history.pushState(null, null, elementClick);
            }
          }
        );
        return false;
      });
    });
  }
})(jQuery);

// Lite tabs
(function($) {
  $.fn.liteTabs = function(options) {
    return this.each(function() {
      var defaults = {
        fadeIn      : false,
        height      : 'auto',
        hideHash    : false,
        hashTag     : true,
        selectedTab : 1,
        width       : 500
      };

      // Merge defaults with options in new settings object
      var settings = $.extend({}, defaults, options);

      // Define key variables
      var $this = $(this);
      var $ul = $this.find('.tab-nav ul');
      var $tab = $ul.find('a');
      var $div = $this.find('.tab-content').first().find('.tab');

      // Tab click
      $tab.click(function(e) {
        var hash = this.hash;
        var id = hash.replace('#','');
        var selectedPanel = $div.filter('[id="' + id + '"]');
        var filterHash = $div.removeClass('selected').filter('[id="' + id + '"]');

        // defaults: add selected class to tab
        $tab.removeClass('selected').filter(this).addClass('selected');

        // Rodičovská záložka zůstane otevřená při kliku na vnořené záložky v záložce
        var parentId = $tab.filter(this).closest('.tab').attr('id');

        $('.tab[id="' + parentId + '"]').addClass('selected');
        $('.tab-nav a[href="' + parentId + '"]').addClass('selected');

        if ($tab.parents('.tabs').length > 1 ) {
          e.preventDefault();
        }

        // Show panel
        filterHash.addClass('selected');

        // Option: hide hash change
        if (settings.hideHash) {
          e.preventDefault();
        }

        return false;
      });

      // Option: set selected tab
      if (settings.selectedTab) {
        $tab.eq(settings.selectedTab - 1).addClass('selected');
        $div.eq(settings.selectedTab - 1).addClass('selected');
      }

      // If hash tag..
      var hash = window.location.hash.substring(1);
      $('[href="#' + hash + '"]').click();
    });
  };
})(jQuery);

// In viewport
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}